

















import ServiceCard from '../components/ServiceCard.vue'
import mixins from 'vue-typed-mixins'
import Services from '../mixins/Services'
export default mixins(Services).extend({
  name: 'Services4',
  components: { ServiceCard }
})
